
import * as BryntumStore from "./BryntumStore.js"
import store from "@/store"
import {
    vm
  } from "@/main"

  


export let project = {
    calendar: "weekends",
    calendarsData: [
      {
        id        : "weekends",
        name      : "Travail",
        intervals : [
            {
                recurrentStartDate : "on Sat at 0:00",
                recurrentEndDate   : "on Mon at 0:00",
                isWorking          : true
            }
        ]
      }
    ],
    delayCalculation:true,

    timeRangeStore: BryntumStore.timeRangeStore,

    //Définition des datas
    resourceStore: BryntumStore.resourcesStore,
    eventStore: BryntumStore.eventsStore,
    assignmentStore: BryntumStore.assignmentStore,

    /* Requêtes au backend */
    autoLoad: true,
    autoSync: true,
    writeAllFields: true,
    // autoSyncTimeout: 500,
    validateResponse: true,
    transport: {
      load: {
        url: `${process.env.VUE_APP_API_ORISIS}Plannings/schedulercrud/load`,
        paramName: "q",
        // credentials: 'omit',
        headers: {
          Authorization: `Bearer ${store.getters.userInfo.token}`
        },

        params: {
          planningViewId: vm.$route.params.id,
          startDate: '2022-01-01T00:00:00Z',
          endDate: '2023-03-02T00:00:00Z',
        },
      },
      sync: {
        url: `${process.env.VUE_APP_API_ORISIS}Plannings/schedulercrud/sync`,
        // credentials: 'omit',
        headers: {
          Authorization: `Bearer ${store.getters.userInfo.token}`
        },
      },
  

    },
  
    onRequestFail: (event) => {
      const {
        requestType,
        response
      } = event,
      serverMessage = response && response.message,
        exceptionText = `Action "${requestType}" failed. ${
		  serverMessage ? ` Server response : ${serverMessage}`: ""
		}`;

      // Toast.show({
      //   html: exceptionText,
      //   color: "b-red",
      //   style: "color:white",
      //   timeout: 10000,
      // });

      console.error(exceptionText);
    },

  };