import {
  StringHelper
} from "@bryntum/schedulerpro";
const {
  xss
} = StringHelper
const columns = [{
  text : "Ligne",
  field: "name",
  hidden: false,
  ascending : true,
  autoWidth:true,
  htmlEncode : false,
  editor : false,
  renderer({ column, row, value }) {
    if(row.cellContext._record.color){
      row.eachElement(el => { 
        el.style.background = row.cellContext._record.color+"40";
        el.style.borderBottom = "1px solid #cccccc50";
      });
    }else{
      row.eachElement(el => { 
        el.style.background = "inherit";
        el.style.borderBottom = "1px solid #cccccc50";
      });
    }
    if(row.cellContext._record.typeLine==3){
      row.eachElement(el => { 
        el.firstChild.style.fontWeight = "600";
        el.firstChild.style.fontSize = "18px";
        el.firstChild.style.borderBottom = "1px solid #cccccc";
      });
    }else if(row.cellContext._record.typeLine==4){
      row.eachElement(el => { 
        el.firstChild.style.fontWeight = "600";
        el.firstChild.style.fontSize = "14px";
        el.firstChild.style.borderBottom = "1px solid #cccccc";
      });
    }else{
      row.eachElement(el => { 
        el.firstChild.style.fontWeight = "400";
        el.firstChild.style.fontSize = "14px";
        el.firstChild.style.borderBottom = "none";
      });
    }
    if(value && value.length>0){
      let valueSlipted = value.split("/")
      if(valueSlipted[1]){
        return `<div class="b-ligne"><span>${valueSlipted[0]}</span><span class="ssligne">${valueSlipted[1]}</span></div>`
      }else{
        return `<div class="b-ligne"><span>${valueSlipted[0]}</span></div>`
      }
    }else{
      return `<div class="b-ligne"><span>${value}</span></div>`
    }
  }
},
{
  text : "Groupe",
  field: "group",
  hidden: true,
  autoWidth:true,
  editor : false
},
{
  text : "TypeLine",
  field: "typeLine",
  hidden: true,
  autoWidth:true,
  editor : false
},
{
  text : "Couleur",
  field: "color",
  hidden: true,
  autoWidth:true
},
{
  text : "Position",
  field: "position",
  hidden: true,
  autoWidth:true,
  editor : false
}]
export {columns}
