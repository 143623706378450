export default {
    

        "localeName": "FR",
        "localeDesc": "Français",
        "Object": {
            "Yes": "Oui",
            "No": "Non",
            "Cancel": "Annuler",
            "Ok": "d'accord",
            "newEvent": "Nouvel évènement"
        },
        "Combo": {
            "noResults": "Aucun résultat",
            "recordNotCommitted": "L'enregistrement n'a pas pu être ajouté"
        },
        "FilePicker": {
            "file": "Fichier"
        },
        "Field": {
            "badInput": "Valeur de champ invalide",
            "patternMismatch": "La valeur doit correspondre à un modèle spécifique",
            "stepMismatch": "La valeur doit correspondre à l'étape",
            "tooLong": "La valeur doit être plus courte",
            "tooShort": "La valeur doit être plus longue",
            "typeMismatch": "La valeur doit être dans un format spécial",
            "valueMissing": "Ce champ est requis",
            "invalidValue": "Valeur de champ invalide",
            "minimumValueViolation": "Violation de la valeur minimale",
            "maximumValueViolation": "Violation de la valeur maximale",
            "fieldRequired": "Ce champ est requis",
            "validateFilter": "La valeur doit être sélectionnée dans la liste"
        },
        "DateField": {
            "invalidDate": "Entrée de date invalide"
        },
        "DatePicker": {
            "gotoPrevYear": "Aller à l'année précédente",
            "gotoPrevMonth": "Aller au mois précédent",
            "gotoNextMonth": "Aller au mois prochain",
            "gotoNextYear": "Aller à l'année prochaine"
        },
        "NumberFormat": {
            "locale": "fr-FR",
            "currency": "EUR"
        },
        "DurationField": {
            "invalidUnit": "Unité invalide"
        },
        "TimeField": {
            "invalidTime": "Saisie d'heure invalide"
        },
        "TimePicker": {
            "hour": "Heure",
            "minute": "Minute"
        },
        "List": {
            "loading": "Chargement..."
        },
        "GridBase": {
            "loadMask": "Chargement...",
            "syncMask": "Enregistrement des modifications, veuillez patienter...",
            "loadFailedMessage": "Le chargement des données a échoué !",
            "syncFailedMessage": "La synchronisation des données a échoué !",
            "unspecifiedFailure": "Échec non spécifié",
            "networkFailure": "Erreur réseau",
            "parseFailure": "Échec de l'analyse de la réponse du serveur",
            "noRows": "Aucun enregistrement à afficher",
            "moveColumnLeft": "Déplacer vers la section de gauche",
            "moveColumnRight": "Déplacer vers la section de droite"
        },
        "PagingToolbar": {
            "firstPage": "Aller à la première page",
            "prevPage": "Aller à la page précédente",
            "page": "Page",
            "nextPage": "Aller à la page suivante",
            "lastPage": "Aller à la dernière page",
            "reload": "Recharger la page actuelle",
            "noRecords": "Aucun enregistrement à afficher"
        },
        "PanelCollapser": {
            "Collapse": "Replier",
            "Expand": "Déplier"
        },
        "Popup": {
            "close": "Fermer la fenêtre"
        },
        "UndoRedo": {
            "Undo": "Annuler",
            "Redo": "Rétablir",
            "UndoLastAction": "Annuler la dernière action",
            "RedoLastAction": "Rétablir la dernière action annulée"
        },
        "DateHelper": {
            "locale": "fr-FR",
            "weekStartDay": 1,
            "nonWorkingDays": {
                "0": true,
                "6": true
            },
            "weekends": {
                "0": true,
                "6": true
            },
            "unitNames": [{
                    "single": "milliseconde",
                    "plural": "Mme",
                    "abbrev": "Mme",
                    "canonicalUnitName": "milliseconde"
                },
                {
                    "single": "seconde",
                    "plural": "secondes",
                    "abbrev": "s",
                    "canonicalUnitName": "seconde"
                },
                {
                    "single": "minute",
                    "plural": "minutes",
                    "abbrev": "min",
                    "canonicalUnitName": "minute"
                },
                {
                    "single": "heure",
                    "plural": "les heures",
                    "abbrev": "h",
                    "canonicalUnitName": "heure"
                },
                {
                    "single": "jour",
                    "plural": "jours",
                    "abbrev": "j",
                    "canonicalUnitName": "journée"
                },
                {
                    "single": "semaine",
                    "plural": "semaines",
                    "abbrev": "w",
                    "canonicalUnitName": "semaine"
                },
                {
                    "single": "mois",
                    "plural": "mois",
                    "abbrev": "lun",
                    "canonicalUnitName": "mois"
                },
                {
                    "single": "trimestre",
                    "plural": "quarts",
                    "abbrev": "q",
                    "canonicalUnitName": "trimestre"
                },
                {
                    "single": "an",
                    "plural": "années",
                    "abbrev": "an",
                    "canonicalUnitName": "an"
                },
                {
                    "single": "décennie",
                    "plural": "décennies",
                    "abbrev": "déc",
                    "canonicalUnitName": "décennie"
                }
            ],
            "unitAbbreviations": [
                [
                    "mil"
                ],
                [
                    "s",
                    "seconde"
                ],
                [
                    "m",
                    "min"
                ],
                [
                    "h",
                    "heure"
                ],
                [
                    "ré"
                ],
                [
                    "w",
                    "semaine"
                ],
                [
                    "mois",
                    "lun",
                    "mnt"
                ],
                [
                    "q",
                    "quart",
                    "qrt"
                ],
                [
                    "y",
                    "an"
                ],
                [
                    "déc"
                ]
            ],
            "parsers": {
                "L": "MM/DD/AAAA",
                "LT": "HH : mm A"
            }
        },
        "TrialButton": {
            "downloadTrial": "Télécharger la version d'évaluation"
        },
        "TrialPanel": {
            "title": "Veuillez remplir les champs",
            "name": "Nom",
            "email": "E-mail",
            "company": "Compagnie",
            "product": "Produit",
            "cancel": "Annuler",
            "submit": "Nous faire parvenir",
            "downloadStarting": "Début du téléchargement, veuillez patienter..."
        },
        "ColumnPicker": {
            "column": "Colonne",
            "columnsMenu": "Colonnes",
            "hideColumn": "Masquer la colonne",
            "hideColumnShort": "Cacher",
            "newColumns": "Nouvelles colonnes"
        },
        "Filter": {
            "applyFilter": "Appliquer le filtre",
            "filter": "Filtre",
            "editFilter": "Modifier le filtre",
            "on": "Au",
            "before": "Avant de",
            "after": "Après",
            "equals": "Équivaut à",
            "lessThan": "Moins que",
            "moreThan": "Plus que",
            "removeFilter": "Supprimer le filtre"
        },
        "FilterBar": {
            "enableFilterBar": "Afficher la barre de filtre",
            "disableFilterBar": "Masquer la barre de filtre"
        },
        "Group": {
            "group": "Grouper",
            "groupAscending": "Groupe ascendant",
            "groupDescending": "Groupe descendant",
            "groupAscendingShort": "Ascendant",
            "groupDescendingShort": "Descendant",
            "stopGrouping": "Arrêter le regroupement",
            "stopGroupingShort": "Arrêter"
        },
        "HeaderMenu": {},
        "MergeCells": {
            "mergeCells": "Fusionner les cellules",
            "menuTooltip": "Fusionner les cellules avec la même valeur lorsqu'elles sont triées par cette colonne"
        },
        "Search": {
            "searchForValue": "Recherche de valeur"
        },
        "Sort": {
            "sort": "Sorte",
            "sortAscending": "Trier par ordre croissant",
            "sortDescending": "Trier par ordre décroissant",
            "multiSort": "Tri multiple",
            "removeSorter": "Supprimer le trieur",
            "addSortAscending": "Ajouter un trieur croissant",
            "addSortDescending": "Ajouter un trieur décroissant",
            "toggleSortAscending": "Passer à l'ascendant",
            "toggleSortDescending": "Changer en décroissant",
            "sortAscendingShort": "Ascendant",
            "sortDescendingShort": "Descendant",
            "removeSorterShort": "Retirer",
            "addSortAscendingShort": "+ Croissant",
            "addSortDescendingShort": "+ Descendant"
        },
        "Column": {
            "cellLabel": ""
        },
        "Checkbox": {
            "toggleRowSelect": "Basculer la sélection de ligne",
            "toggleSelection": "Basculer la sélection de l'ensemble de données entier"
        },
        "RatingColumn": {},
        "CellMenu": {
            "removeRow": "Supprimer"
        },
        "RowCopyPaste": {
            "copyRecord": "Copie",
            "cutRecord": "Couper",
            "pasteRecord": "Pâte"
        },
        "PdfExport": {
            "Waiting for response from server": "En attente de réponse du serveur...",
            "Export failed": "Échec de l'exportation",
            "Server error": "Erreur du serveur",
            "Generating pages": "Génération de pages..."
        },
        "ExportDialog": {
            "width": "40em",
            "labelWidth": "12em",
            "exportSettings": "Paramètres d'exportation",
            "export": "Exportation",
            "exporterType": "Contrôler la pagination",
            "cancel": "Annuler",
            "fileFormat": "Format de fichier",
            "rows": "Lignes",
            "alignRows": "Aligner les lignes",
            "columns": "Colonnes",
            "paperFormat": "Format papier",
            "orientation": "Orientation",
            "repeatHeader": "Répéter l'en-tête"
        },
        "ExportRowsCombo": {
            "all": "Toutes les lignes",
            "visible": "Lignes visibles"
        },
        "ExportOrientationCombo": {
            "portrait": "Portrait",
            "landscape": "Paysage"
        },
        "SinglePageExporter": {
            "singlepage": "Une seule page"
        },
        "MultiPageExporter": {
            "multipage": "Pages multiples"
        },
        "MultiPageVerticalExporter": {
            "multipagevertical": "Plusieurs pages (verticales)"
        },
        "ResourceInfoColumn": {},
        "Dependencies": {
            "from": "À partir de",
            "to": "Pour",
            "valid": "Valide",
            "invalid": "Invalide"
        },
        "DependencyType": {
            "SS": "SS",
            "SF": "SF",
            "FS": "FS",
            "FF": "FF",
            "StartToStart": "De bout en bout",
            "StartToEnd": "Indemnite",
            "EndToStart": "De la fin au début",
            "EndToEnd": "Fin à Fin",
            "short": [
                "SS",
                "SF",
                "FS",
                "FF"
            ],
            "long": [
                "De bout en bout",
                "Indemnite",
                "De la fin au début",
                "Fin à Fin"
            ]
        },
        "DependencyEdit": {
            "From": "À partir de",
            "To": "Pour",
            "Type": "Taper",
            "Lag": "Décalage",
            "Edit dependency": "Modifier la dépendance",
            "Save": "Enregistrer",
            "Delete": "Supprimer",
            "Cancel": "Annuler",
            "StartToStart": "Commencer à commencer",
            "StartToEnd": "Du début à la fin",
            "EndToStart": "Fin pour commencer",
            "EndToEnd": "De bout en bout",
            "Active": "actif"
        },
        "EventEdit": {
            "Name": "Nom",
            "Resource": "Ressource",
            "Start": "Date de Début",
            "End": "Date de fin",
            "Save": "Enregistrer",
            "Delete": "Supprimer",
            "Cancel": "Annuler",
            "Edit event": "Modifier l'événement",
            "Repeat": "Répéter"
        },
        "EventDrag": {
            "eventOverlapsExisting": "L'événement chevauche l'événement existant pour cette ressource",
            "noDropOutsideTimeline": "L'événement ne peut pas être supprimé complètement en dehors de la chronologie"
        },
        "SchedulerBase": {
            "Add event": "Ajouter un évènement",
            "Delete event": "Supprimer l'événement",
            "Unassign event": "Désattribuer l'événement"
        },
        "TimeAxisHeaderMenu": {
            "pickZoomLevel": "Zoom",
            "activeDateRange": "Période par défaut",
            "startText": "Date de début",
            "endText": "Date de fin",
            "todayText": "Aujourd'hui"
        },
        "EventCopyPaste": {
            "copyEvent": "Copier l'événement",
            "cutEvent": "Couper l'événement",
            "pasteEvent": "Coller l'événement"
        },
        "EventFilter": {
            "filterEvents": "Filtres d'événements",
            "byName": "De nom"
        },
        "TimeRanges": {
            "showCurrentTimeLine": "Afficher la chronologie actuelle"
        },
        "PresetManager": {
            "secondAndMinute": {
                "displayDateFormat": "ll LTS"
            },
            "minuteAndHour": {
                "topDateFormat": "ddd MM/DD, hA",
                "displayDateFormat": "ll LST"
            },
            "hourAndDay": {
                "topDateFormat": "ddd MM/DD",
                "middleDateFormat": "LST",
                "displayDateFormat": "ll LST"
            },
            "weekAndDay": {
                "displayDateFormat": "ll LST"
            },
            "dayAndWeek": {
                "displayDateFormat": "ll LST"
            }
        },
        "RecurrenceConfirmationPopup": {
            "delete-title": "Vous supprimez un événement",
            "delete-all-message": "Voulez-vous supprimer toutes les occurrences de cet événement ?",
            "delete-further-message": "Voulez-vous supprimer cet événement et toutes les occurrences futures de cet événement, ou uniquement l'occurrence sélectionnée ?",
            "delete-further-btn-text": "Supprimer tous les événements futurs",
            "delete-only-this-btn-text": "Supprimer uniquement cet événement",
            "update-title": "Vous modifiez un événement récurrent",
            "update-all-message": "Voulez-vous modifier toutes les occurrences de cet événement ?",
            "update-further-message": "Voulez-vous modifier uniquement cette occurrence de l'événement, ou celle-ci et toutes les occurrences futures ?",
            "update-further-btn-text": "Tous les événements futurs",
            "update-only-this-btn-text": "Uniquement cet événement",
            "Yes": "Oui",
            "Cancel": "Annuler",
            "width": 600
        },
        "RecurrenceLegend": {
            " and ": "et",
            "Daily": "du quotidien",
            "position1": "la première",
            "position2": "la deuxième",
            "position3": "le troisième",
            "position4": "le quatrième",
            "position5": "le cinquième",
            "position-1": "le dernier",
            "day": "journée",
            "weekday": "jour de la semaine",
            "weekend day": "jour de weekend"
        },
        "RecurrenceEditor": {
            "Repeat event": "Répéter l'événement",
            "Cancel": "Annuler",
            "Save": "Enregister",
            "Frequency": "La fréquence",
            "Every": "Tous",
            "DAILYintervalUnit": "journées)",
            "WEEKLYintervalUnit": "semaines)",
            "MONTHLYintervalUnit": "mois)",
            "YEARLYintervalUnit": "années)",
            "Each": "Chaque",
            "On the": "Sur le",
            "End repeat": "Fin de répétition",
            "time(s)": "fois)"
        },
        "RecurrenceDaysCombo": {
            "day": "journée",
            "weekday": "jour de la semaine",
            "weekend day": "jour de weekend"
        },
        "RecurrencePositionsCombo": {
            "position1": "première",
            "position2": "seconde",
            "position3": "la troisième",
            "position4": "Quatrième",
            "position5": "cinquième",
            "position-1": "dernier"
        },
        "RecurrenceStopConditionCombo": {
            "Never": "Jamais",
            "After": "Après",
            "On date": "À la date"
        },
        "RecurrenceFrequencyCombo": {
            "Daily": "du quotidien",
            "Weekly": "Hebdomadaire",
            "Monthly": "Mensuel",
            "Yearly": "Annuel"
        },
        "RecurrenceCombo": {
            "None": "Aucun",
            "Custom": "Personnalisé..."
        },
        "Summary": {},
        "ScheduleRangeCombo": {
            "completeview": "Horaire complet",
            "currentview": "Horaire visible",
            "daterange": "Plage de dates",
            "completedata": "Horaire complet (pour tous les événements)"
        },
        "SchedulerExportDialog": {
            "Schedule range": "Plage horaire",
            "Export from": "À partir de",
            "Export to": "Pour"
        },
        "ExcelExporter": {
            "No resource assigned": "Aucune ressource affectée"
        },
        "CrudManagerView": {
            "serverResponseLabel": "Réponse du serveur :"
        },
        "DurationColumn": {
            "Duration": "Durée"
        },
        "RemoveDependencyCycleEffectResolution": {
            "descriptionTpl": "Supprimer la dépendance"
        },
        "DeactivateDependencyCycleEffectResolution": {
            "descriptionTpl": "Désactiver la dépendance"
        },
        "CycleEffectDescription": {
            "descriptionTpl": "Un cycle a été trouvé, formé par : {0}"
        },
        "EmptyCalendarEffectDescription": {
            "descriptionTpl": "Le calendrier &quot;{0}&quot; ne fournit aucun intervalle de temps de travail."
        },
        "Use24hrsEmptyCalendarEffectResolution": {
            "descriptionTpl": "Utilisez un calendrier de 24 heures avec les samedis et dimanches chômés."
        },
        "Use8hrsEmptyCalendarEffectResolution": {
            "descriptionTpl": "Utilisez un calendrier de 8 heures (08h00-12h00, 13h00-17h00) avec les samedis et dimanches non ouvrables."
        },
        "ConflictEffectDescription": {
            "descriptionTpl": "Un conflit d'horaire a été trouvé : {0} est en conflit avec {1}"
        },
        "ConstraintIntervalDescription": {
            "dateFormat": "JE VAIS"
        },
        "ProjectConstraintIntervalDescription": {
            "startDateDescriptionTpl": "Date de début du projet {0}",
            "endDateDescriptionTpl": "Date de fin du projet {0}"
        },
        "DependencyConstraintIntervalDescription": {
            "descriptionTpl": "Dépendance ({2}) de <strong>{3}</strong> à <strong>{4}</strong>"
        },
        "RemoveDependencyResolution": {
            "descriptionTpl": "Supprimer la dépendance de &quot;{1}&quot; à &quot;{2}&quot;"
        },
        "DeactivateDependencyResolution": {
            "descriptionTpl": "Désactiver la dépendance de &quot;{1}&quot; à &quot;{2}&quot;"
        },
        "DateConstraintIntervalDescription": {
            "startDateDescriptionTpl": "Tâche <strong>{2}</strong> {3} {0} contrainte",
            "endDateDescriptionTpl": "Tâche <strong>{2}</strong> {3} {1} contrainte",
            "constraintTypeTpl": {
                "startnoearlierthan": "Commencer-Au-Plus-Tôt-Le",
                "finishnoearlierthan": "Terminer au plus tôt",
                "muststarton": "Doit commencer",
                "mustfinishon": "Doit-Finir-On",
                "startnolaterthan": "Commencer au plus tard",
                "finishnolaterthan": "Terminer au plus tard"
            }
        },
        "RemoveDateConstraintConflictResolution": {
            "descriptionTpl": "Supprimer la contrainte &quot;{1}&quot; de la tâche &quot;{0}&quot;"
        },
        "ConstraintTypePicker": {
            "none": "Aucun",
            "muststarton": "Doit commencer le",
            "mustfinishon": "Doit finir le",
            "startnoearlierthan": "Commencer au plus tôt le",
            "startnolaterthan": "Commencer au plus tard le",
            "finishnoearlierthan": "Terminer au plus tôt le",
            "finishnolaterthan": "Terminer au plus tard le"
        },
        "CalendarField": {
            "Default calendar": "Calendrier par défaut"
        },
        "TaskEditorBase": {
            "Information": "Information",
            "Save": "Enregister",
            "Cancel": "Annuler",
            "Delete": "Supprimer",
            "calculateMask": "Calculateur...",
            "saveError": "Impossible d'enregistrer, veuillez d'abord corriger les erreurs"
        },
        "TaskEdit": {
            "Edit task": "Modifier la tâche",
            "ConfirmDeletionTitle": "Confirmer la suppression",
            "ConfirmDeletionMessage": "Voulez-vous vraiment supprimer l'événement ?"
        },
        "GanttTaskEditor": {
            "editorWidth": "44em"
        },
        "SchedulerTaskEditor": {
            "editorWidth": "32em"
        },
        "SchedulerGeneralTab": {
            "labelWidth": "6em",
            "General": "Général",
            "Name": "Nom",
            "Resources": "Ressources",
            "% complete": "% Achevée",
            "Duration": "Durée",
            "Start": "Début",
            "Finish": "Fin",
            "Preamble": "Préambule",
            "Postamble": "Postambule"
        },
        "GeneralTab": {
            "labelWidth": "6.5em",
            "General": "Général",
            "Name": "Nom",
            "% complete": "% Achevée",
            "Duration": "Durée",
            "Start": "Début",
            "Finish": "Fin",
            "Effort": "Effort",
            "Dates": "Dates"
        },
        "SchedulerAdvancedTab": {
            "labelWidth": "13em",
            "Calendar": "Calendrier",
            "Advanced": "Avancée",
            "Manually scheduled": "Planifié manuellement",
            "Constraint type": "Type de contrainte",
            "Constraint date": "Date contrainte",
            "Inactive": "Inactif"
        },
        "AdvancedTab": {
            "labelWidth": "11.5em",
            "Advanced": "Avancée",
            "Calendar": "Calendrier",
            "Scheduling mode": "Mode de planification",
            "Effort driven": "Effort entraîné",
            "Manually scheduled": "Planifié manuellement",
            "Constraint type": "Type de contrainte",
            "Constraint date": "Date contrainte",
            "Constraint": "Contrainte",
            "Rollup": "Cumul",
            "Inactive": "Inactif"
        },
        "DependencyTab": {
            "Predecessors": "Prédécesseurs",
            "Successors": "Successeurs",
            "ID": "identifiant",
            "Name": "Nom",
            "Type": "Taper",
            "Lag": "Décalage",
            "cyclicDependency": "Dépendance cyclique",
            "invalidDependency": "Dépendance invalide"
        },
        "NotesTab": {
            "Notes": "Remarques"
        },
        "ResourcesTab": {
            "Resources": "Ressources",
            "Resource": "Ressource",
            "Units": "Unités"
        },
        "SchedulingModePicker": {
            "Normal": "Normal",
            "Fixed Duration": "Durée fixe",
            "Fixed Units": "Unités fixes",
            "Fixed Effort": "Effort fixe"
        },
        "ResourceHistogram": {
            "barTipInRange": "<b>{resource}</b> {startDate} - {endDate}<br> <span class=\"{cls}\">{allocated} sur {available}</span> alloué",
            "barTipOnDate": "<b>{resource}</b> sur {startDate}<br> <span class=\"{cls}\">{allocated} sur {available}</span> alloué",
            "groupBarTipAssignment": "<b>{resource}</b> - <span class=\"{cls}\">{allocated} de {available}</span>",
            "groupBarTipInRange": "{startDate} - {endDate}<br> <span class=\"{cls}\">{allocated} sur {available}</span> alloué :<br> {assignments}",
            "groupBarTipOnDate": "Sur {startDate}<br> <span class=\"{cls}\">{allocated} sur {available}</span> alloué :<br> {assignments}",
            "plusMore": "+{value} de plus"
        },
        "ResourceUtilization": {
            "barTipInRange": "<b>{event}</b> {startDate} - {endDate}<br> <span class=\"{cls}\">{allocated}</span> alloué",
            "barTipOnDate": "<b>{event}</b> sur {startDate}<br> <span class=\"{cls}\">{allocated}</span> alloué",
            "groupBarTipAssignment": "<b>{event}</b> - <span class=\"{cls}\">{allocated}</span>",
            "groupBarTipInRange": "{startDate} - {endDate}<br> <span class=\"{cls}\">{allocated} sur {available}</span> alloué :<br> {assignments}",
            "groupBarTipOnDate": "Sur {startDate}<br> <span class=\"{cls}\">{allocated} sur {available}</span> alloué :<br> {assignments}",
            "plusMore": "+{value} de plus",
            "nameColumnText": "Ressource / Événement"
        },
        "SchedulingIssueResolutionPopup": {
            "Cancel changes": "Annuler la modification et ne rien faire",
            "schedulingConflict": "Conflit d'horaire",
            "emptyCalendar": "Erreur de configuration du calendrier",
            "cycle": "Cycle de planification",
            "Apply": "Appliquer"
        },
        "CycleResolutionPopup": {
            "dependencyLabel": "Veuillez sélectionner une dépendance pour appliquer une modification ci-dessous à :",
            "invalidDependencyLabel": "Il y a des dépendances non valides impliquées qui doivent être corrigées :"
        },
        "SchedulerProBase": {
            "propagating": "Chargement des données",
            "storePopulation": "Chargement des données",
            "finalizing": "Finalisation des résultats"
        }
    
    
    }
