import store from '@/store'
import {
  Scheduler
} from '@/views/Bryntum.vue'
export const listeners = {
  beforeTaskEdit(data){
    console.log('>> beforeTaskEdit', data )
     // > Chargement des données dans l'editor
    data.taskEdit.editor._widgetMap.eventTypeId.items = store.getters.eventTypesList.map(el => ({
      text: el.label,
      value: el.id,
      color: el.color,
      data: el
    }));
    data.taskEdit.editor._widgetMap.companiesList.items = store.getters.companiesList.map(el => ({
      text:  el.name,
      value: el.id,
      color: el.color,
      data: el
    }));
    data.taskEdit.editor._widgetMap.affairsList.items = store.getters.affairsList.map(el => ({
      text: el.name,
      value: el.id,
      color: el.color,
      data: el
    }));
  },
  beforeCopy(event) {
    console.log('>> beforeCopy', event)
  },

  beforePaste({
    source,
    records,
    date,
    resourceRecord,
    isCut
  }) {
    console.log(">> beforePaste", source, records, date, resourceRecord, isCut)
    if(!resourceRecord.PlanningLineId || resourceRecord.typeLine==3 || resourceRecord.typeLine==4){

    }else{
      if (isCut) {
        return true
      } else {
        records.forEach(record => {
          const selectedFacilities = [resourceRecord.id];
          const selectedStaff = record.assignments.map(x => x.resource.id);
          const selectedResourceIds = [...selectedFacilities];


          var newRecords = Scheduler.instance.eventStore.add({
            Cls: record.get('Cls'),
            // EndDate: record.get('EndDate'),
            StartDate: date,
            AffairId: record.get('AffairId') == 0 ? null : record.get('AffairId'),
            CompanyId: record.get('CompanyId') == 0 ? null : record.get('CompanyId'),
            Comments: record.get('Comments') == 0 ? null : record.get('Comments'),
            EventTypeId: record.get('EventTypeId'),
            HoursDay: record.get('HoursDay'),
            PlanningLineId:!record.get('PlanningLineId') ? 0 : record.get('PlanningLineId'),
            Name: record.get('Name'),
            IsHidden: record.get('IsHidden'),
            IsLocked: record.get('IsLocked'),
            allDay: record.get('allDay'),
            constraintDate: record.get('constraintDate'),
            constraintType: record.get('constraintType'),
            direction: record.get('direction'),
            duration: record.get('duration'),
            durationUnit: record.get('durationUnit'),
            exceptionDates: record.get('exceptionDates'),
            inactive: record.get('inactive'),
            manuallyScheduled: record.get('manuallyScheduled'),
            percentDone: record.get('percentDone'),
            postamble: record.get('postamble'),
            preamble: record.get('preamble'),
          });

          newRecords[0].assign(selectedResourceIds);
          return false;

        })
      }
    }
    return false

  },
  // beforePaste({
  //   source,
  //   records,
  //   date,
  //   resourceRecord,
  //   isCut
  // }) {
  //   if (isCut) {
  //     return true
  //   } else {
  //     records.forEach(record => {

  //       const selectedFacilities = [resourceRecord.id];
  //       const selectedStaff = record.assignments.map(x => x.resource.id);
  //       const selectedResourceIds = [...selectedFacilities];


  //       var newRecords = Scheduler.instance.eventStore.add({
  //         Cls: record.get('Cls'),
  //         EndDate: record.get('EndDate'),
  //         AffairId: record.get('AffairId') == 0 ? null : record.get('AffairId'),
  //         CompanyId: record.get('CompanyId') == 0 ? null : record.get('CompanyId'),
  //         Comments: record.get('Comments') == 0 ? null : record.get('Comments'),
  //         EventTypeId: record.get('EventTypeId'),
  //         HoursDay: record.get('HoursDay'),
  //         PlanningLineId:!record.get('PlanningLineId') ? 0 : record.get('PlanningLineId'),
  //         Name: record.get('Name'),
  //         IsHidden: record.get('IsHidden'),
  //         IsLocked: record.get('IsLocked'),
  //         StartDate: date,
  //         allDay: record.get('allDay'),
  //         constraintDate: record.get('constraintDate'),
  //         constraintType: record.get('constraintType'),
  //         direction: record.get('direction'),
  //         duration: record.get('duration'),
  //         durationUnit: record.get('durationUnit'),
  //         exceptionDates: record.get('exceptionDates'),
  //         inactive: record.get('inactive'),
  //         manuallyScheduled: record.get('manuallyScheduled'),
  //         percentDone: record.get('percentDone'),
  //         postamble: record.get('postamble'),
  //         preamble: record.get('preamble'),
  //       });

  //       newRecords[0].assign(selectedResourceIds);
  //       return false;

  //     })
  //   }
  //   return false

  // },
  beforeTaskEditShow({
    editor,
    taskRecord
  }) {
    console.log(">> beforeTaskEditShow", editor, taskRecord)

    editor.widgetMap.companiesContainer.hidden = true
    editor.widgetMap.companiesList.hidden = true
    editor.widgetMap.affairsContainer.hidden = true
    editor.widgetMap.affairsList.hidden = true
    editor.widgetMap.phoneField.items = []
    if(taskRecord.resource._data.PlanningLineId==null){
      // Si la ligne n'est pas associée à une planning Line, on empèche la programmation
      return false
    }else{
      // On ouvre l'éditor d'événement
      // > Récupération des détails des ressources associées */
      // console.log(Scheduler.instance._project.resourceStore._data)
      // console.log(editor.widgetMap.resourcesField.value, Scheduler, Scheduler.instance._project.resourceStore._data.filter(el => editor.widgetMap.resourcesField.value.includes(el.id)).map(el => el.CollaboratorId))
      //let collaboratorIds = editor.widgetMap.resourcesField.items.filter(el=> editor.widgetMap.resourcesField.value.includes(el.id)).map(el => el.CollaboratorId); 
      let collaboratorIds = Scheduler.instance._project.resourceStore._data.filter(el => editor.widgetMap.resourcesField.value.includes(el.Id)).map(el => el.CollaboratorId);
      let collaborators = store.getters.collaboratorsList.filter(el => collaboratorIds.includes(el.id) && el.phoneNumber!==null);
      // console.log(collaboratorIds, collaborators)
      // > Traitement si l'événément a des collaborateurs avec numéro de téléphone
      if(collaborators.length>0){
        editor.widgetMap.alerteSmsField.hidden=true
        editor.widgetMap.smsForm.hidden=false
        editor.widgetMap.phoneField.items = collaborators
        editor.widgetMap.phoneField.hidden=false
        editor.widgetMap.dateSmsField.value = new Date()
      }else{
        editor.widgetMap.alerteSmsField.html = "<div class='alert-warning text-warning p-1 mb-1'>Pour programmer un SMS, votre événement doit être associé à au moins un collaborateur disposant d'un numéro de téléphone renseigné dans votre base de données.</div>"
        editor.widgetMap.alerteSmsField.hidden=false
        editor.widgetMap.smsForm.hidden=true
      }

      if(taskRecord.EventTypeId){
        // console.log("Traitement si l'événément est en mode UPDATE",taskRecord.EventTypeId)
        // > Traitement si l'événément est en mode UPDATE
        editor.widgetMap.nameField.value=taskRecord.name
        let updateEventType = store.getters.eventTypesList.find(el => el.id == taskRecord.EventTypeId);
        editor.widgetMap.eventTypeId.value=updateEventType ? updateEventType.id : null

        // console.log("updateEventType",updateEventType)
        if (updateEventType.isDependent) {
          editor.widgetMap.companiesList.value = store.getters.companiesList.find(el => el.id == taskRecord.CompanyId) ? store.getters.companiesList.find(el => el.id == taskRecord.CompanyId).id : null
          editor.widgetMap.companiesContainer.hidden = false
          editor.widgetMap.companiesList.hidden = false
          editor.widgetMap.affairsList.value = store.getters.affairsList.find(el => el.id == taskRecord.AffairId) ? store.getters.affairsList.find(el => el.id == taskRecord.AffairId).id : null
          editor.widgetMap.affairsContainer.hidden = false
          editor.widgetMap.affairsList.hidden = false
        } else {
          editor.widgetMap.companiesContainer.hidden = true
          editor.widgetMap.companiesList.hidden = true
          editor.widgetMap.affairsContainer.hidden = true
          editor.widgetMap.affairsList.hidden = true
        }
      }else{
        // > Traitement si l'événément est en mode CREATE
        // > On initialize le formulaire
        editor.widgetMap.eventTypeId.value=null
        editor.widgetMap.eventTypeId.hidden=false
        editor.widgetMap.companiesList.value=null
        editor.widgetMap.companiesContainer.hidden=true
        editor.widgetMap.companiesList.hidden=true
        editor.widgetMap.affairsList.value=null
        editor.widgetMap.affairsContainer.hidden=true
        editor.widgetMap.affairsList.hidden=true
      }

      return true
    }
  }
}
