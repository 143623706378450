
import { StringHelper, DateHelper, Scheduler } from "@bryntum/schedulerpro";
import dayjs from 'dayjs'
const { xss } = StringHelper
import {
  columns
} from "./columns.js"
import {
  viewPreset
} from "./viewPreset.js"

import {
  project
} from "./project.js"
import {
  listeners
} from "./listeners.js"
import {
  toolBar
} from "./toolBar.js"
import {
  features
} from "./features.js";


const schedulerConfig = {
  useInitialAnimation: "slide-from-left",
  eventColor: "#0b3571",
  flex     : '1 0 100%',

  startDate: dayjs().add(-2, 'week').toDate(),
  endDate: dayjs().add(12, 'week').toDate(),

  rowHeight: 50,
  barMargin: 5,
  weekStartDay: 1,
  /* Setting the default values for the calendar. */
  snap: true,
  multiEventSelect: true,
  passStartEndParameters: true, // retour de l'api envoie une date de début ET une date de fin de l'evenement

  /* FONCTIONALITES  */
  features: features,
  /* DATA API STORE ETC  */
  project: project,
  /* BARRE D'OUTILS */
  tbar: toolBar,
  /* PARAMETRES D'AFFICHAGE DU PLANNING (TEMPORALITE ETC...) */
  viewPreset: viewPreset,
  /* PARAMETRES D'AFFICHAGE DES COLLONNES DE GAUCHE (Nom des collabs etc...) */
  columns: columns,
  
  listeners : listeners,

  
}

export async function init() {
  return new Promise((resolve, reject) => {
    resolve(schedulerConfig)
  })
}
