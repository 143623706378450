import { Scheduler, TimeSpan, RecurringTimeSpan, RecurringTimeSpansMixin, Store } from "@bryntum/schedulerpro";
import store from '@/store'
import dayjs from 'dayjs'

class MyTimeRange extends RecurringTimeSpan(TimeSpan) {}

// Define a new store extending the Store class
// with RecurringTimeSpansMixin mixin to add recurrence support to the store.
// This store will contain time ranges.
class MyTimeRangeStore extends RecurringTimeSpansMixin(Store) {
    static get defaultConfig() {
        return {
            // use our new MyTimeRange model
            modelClass : MyTimeRange,
            storeId    : 'timeRanges'
        };
    }
}

// instantiate store for time ranges using our new classes
export const timeRangeStore = new MyTimeRangeStore({
  data :[ {
      id             : 0,
      name           : "",
      recurrenceRule : "FREQ=WEEKLY",
      startDate      : "2000-01-01",
      duration       : 2,
      durationUnit   : "day",
      cls            : "b-cols-weekend"
    },
    {
      id             : 1,
      name           : "",
      startDate      : dayjs().format("YYYY-MM-DD"),
      duration       : 1,
      durationUnit   : "day",
      cls            : "b-cols-today"
    },
    ...store.getters.publicHolidayList
  ]
  
 
});



export const eventsStore = {
    fields: [{
        name: "id",
        dataSource: "Id"
      },
      {
        name: "name",
        dataSource: "Name"
      },
      {
        name: "startDate",
        dataSource: "StartDate",
        type: "date"
      },
      {
        name: "endDate",
        dataSource: "EndDate",
        type: "date"
      },
      {
        name: "PlanningLineId",
        dataSource: "PlanningLineId",
        convert   : (v) => Number(v),
      },
      {
        name: 'EventTypeId',
        dataSource: 'EventTypeId'
      },
      {
        name: 'AffairId',
        dataSource: 'AffairId'
      },
      {
        name: 'CompanyId',
        dataSource: 'CompanyId'
      },
      {
        name: 'IsHidden',
        dataSource: 'IsHidden'
      },
      {
        name: 'IsLocked',
        dataSource: 'IsLocked'
      },
      {
        name: 'HoursDay',
        dataSource: 'HoursDay'
      },
      {
        name: 'Comments',
        dataSource: 'Comments'
      },
      {
        name: 'style',
        dataSource: 'Cls'
      },
      {
        name: 'cls',
        dataSource: 'Cls'
      },
      // {
      //   name: "draggable",
      //   dataSource: "Draggable",
      // },
      // {
      //   name: "resizable",
      //   dataSource: "Resizable",
      // }
    ],
}

export const resourcesStore = {
  id:"resourcePlanning",
  fields: [{
      name: "id",
      dataSource: "Id",

    },
    {
      name: "name",
      dataSource: "Name"
    },
    {
      name: "subName",
      dataSource: "SubName"
    },
    {
      name: "group",
      dataSource: "Group"
    },
    {
      name: "color",
      dataSource: "Color"
    },
    {
      name: "position",
      dataSource: "Position"
    },
    {
      name: "typeLine",
      dataSource: "TypeLine"
    }
  ],

};
export const assignmentStore = {
  fields: [{
      name: "id",
      dataSource: "Id",

    },
    {
      name: "resourceId",
      dataSource: "ResourceId"
    },
    {
      name: "eventId",
      dataSource: "EventId"
    },
  ],
}
// export const timeRangeStore = {
//   data : store.getters.publicHolidayList
// }