import {DateHelper} from  "@bryntum/schedulerpro";

let viewPreset = {
  base          : "dayAndWeek",
  tickWidth     : 40,
  timeResolution: {
    unit     : "day",
    increment: 1,
  },
  headers: [{
      unit      : "month",
      dateFormat: "MMMM YYYY",
      align     : "start",
    },
    {
      unit    : "week",
      renderer: function (start) {
        return (
          "Semaine  <strong>" + DateHelper.format(start, "WW") + "</strong>"
        );
      },
    },
    {
      unit    : "day",
      renderer: (start, end, headerConfig, index) => {
        headerConfig.headerCellCls = "text-center";
        // if (
        //   DateHelper.format(start, "YYYY-MM-DD") ==
        //   DateHelper.format(new Date(), "YYYY-MM-DD")
        // ) {
        //   headerConfig.headerCellCls = "text-center bg-primary text-white";
        //   return (
        //     DateHelper.format(start, "dd") +
        //     "<br><strong>" +
        //     DateHelper.format(start, "DD") +
        //     "</strong>"
        //   );
        // }
        return (
          DateHelper.format(start, "dd") +
          "<br><strong>" +
          DateHelper.format(start, "DD") +
          "</strong>"
        );
      },
    },
  ],
}
export {viewPreset}
