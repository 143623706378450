import store from '@/store'
import {
  StringHelper, DateHelper
} from "@bryntum/schedulerpro";
import {
  Scheduler
} from "@/views/Bryntum.vue"
const {
  xss
} = StringHelper

import router from '@/router'

export let features = {
    stripe: true,
    resourceTimeRanges: true,


    dependencies: false,
    scheduleContext: true,
    eventCopyPaste: true,

    filterBar: true,
    resourceNonWorkingTime: true,

    nonWorkingTime: {
      highlightWeekends: true
    },
    // scheduleTooltip : {
    //   getText(date, event, resource) {
    //     console.log(date, event, resource)
    //       return 'Hovering ' + resource.name;
    //   }
    // },
    eventTooltip : {
      cls:"b-tooltip-custom",
      // Tooltip configs can be used here
      // align : 'l-r',
      // A custom HTML template
      // <dt>Assigned to:</dt>
      // <dt>Time:</dt><dt>Détails :</dt>
      // <dd>
      //     ${DateHelper.format(data.eventRecord.startDate, 'LT')} - ${DateHelper.format(data.eventRecord.endDate, 'LT')}
      // </dd>
      // ${data.eventRecord.get('image') ? `<dt>Image:</dt><dd><img class="image" src="${data.eventRecord.get('image')}"/></dd>` : ''}
      template : data =>{
        let uniteHebdo = "jour";
        let company = "";
        let affair = "";
        let hours = "";
        if(data.eventRecord.EventTypeId && data.eventRecord.EventTypeId>0 && store.getters.eventTypesList.find(elem=>elem.id==data.eventRecord.EventTypeId)){
            if(store.getters.eventTypesList.find(elem=>elem.id==data.eventRecord.EventTypeId)?.label.includes("nuit")){
              uniteHebdo="nuit"
            }
        }
        if(data.eventRecord.CompanyId && data.eventRecord.CompanyId>0 && store.getters.companiesList.find(elem=>elem.id==data.eventRecord.CompanyId)){
          company='<dt>'+store.getters.companiesList.find(elem=>elem.id==data.eventRecord.CompanyId)?.name+'</dt>'
        }
        if(data.eventRecord.AffairId && data.eventRecord.AffairId>0){
          affair='<dt>'+store.getters.affairsList.find(elem=>elem.id==data.eventRecord.AffairId)?.name+'</dt>'
        }
        if(data.eventRecord.HoursDay && data.eventRecord.HoursDay>0){
          hours=' / '+data.eventRecord.HoursDay+'h/'+uniteHebdo
        }
      
        return `<dl class="mb-0 pb-0">
                  <dt>${store.getters.eventTypesList.find(elem=>elem.id==data.eventRecord.EventTypeId)?.label}${hours}</dt>
                  ${company}
                  ${affair}
                  ${data.eventRecord.get('Comments') ? `<dd class="mb-0 pb-0">${data.eventRecord.Comments}</dd>` : ''}
                </dl>`;
      }
    },
    

    taskEdit: {
      items: {
        generalTab: {
          activeIndex:0,
          weight: 0,
          order: 0,
          position: 0,
          title: "Général",

          items: {
            percentDoneField: false,
            eventTypeId: {
              ref: "eventTypeId",
              name: "EventTypeId",
              label: "Type",
              type: 'combo',
              required: true,
              items: store.getters.eventTypesList.map(el => ({
                text: el.label,
                value: el.id,
                color: el.color,
                data: el
              })),
              listItemTpl: item => xss `<div class="pin-color" style="background-color:${(item.color!==''?item.color:'#0c3571')} !important"></div><div>${item.text}</div>`,
              hidden: false,
              picker: {},
              readOnly: false,
              value: '',
              onChange({
                source,
                value
              }) {
                if (value && value !== "") {
                  const dataTypeEvent = store.getters.eventTypesList.find(el => el.id == value)
                  if (dataTypeEvent?.color && dataTypeEvent?.color !== "") {
                    source.parent.widgetMap.styleField.value = 'background-color:' + dataTypeEvent.color + ' !important';
                  }
                  if (dataTypeEvent?.isDependent) {
                    source.parent.widgetMap.companiesContainer.show()
                    source.parent.widgetMap.companiesList.show()
                    if (dataTypeEvent) source.parent.widgetMap.nameField.value = dataTypeEvent.label
                  } else {
                    source.parent.widgetMap.companiesContainer.hide()
                    source.parent.widgetMap.companiesList.hide()
                    source.parent.widgetMap.affairsContainer.hide()
                    source.parent.widgetMap.affairsList.hide()
                    if (dataTypeEvent)  source.parent.widgetMap.nameField.value = dataTypeEvent.label
                  }
                }
              }
            },
            companiesContainer:{
              ref: "companiesContainer",
              type: 'container',
              name: 'companiesContainer',
              cls:'',
              hidden: true,
              width:'100%',
              items:{
                companiesList: {
                  ref: "companiesList",
                  name: "CompanyId",
                  label: "Entreprise",
                  type: 'combo',
                  flex:1,
                  required: true,
                  items: store.getters.companiesList.map(el => ({
                    text:  el.name,
                    value: el.id,
                    color: el.color,
                    data: el
                  })),
                  listItemTpl: item => xss `<div class="pin-color" style="background-color:${(item.color!==''?item.color:'#0c3571')} !important"></div><div>${item.text}</div>`,
                  
                  hidden: true,
                  picker: {},
                  readOnly: false,
                  onChange({
                    source, value
                  }) {
                    if (source.record?.data?.color && source.record?.data?.color !== "") {
                      source.parent.parent.widgetMap.styleField.value = 'background-color:' + source.record.data.color + ' !important';
                    }
                    if(value>0){
                      source.parent.parent.widgetMap.affairsContainer.show()
                      source.parent.parent.widgetMap.affairsList.show()
                    }
                  }
                }
                // addCompany:{
                //   type: 'button',
                //   cls:'btn b-transparent btn-outline-primary', 
                //   text: "",
                //   color   : 'outline-primary',
                //   icon    : 'b-fa-thin b-fa-plus',
                //   style   : 'max-height: 38px; margin-top: 19px;',
                //   onClick() {
                //     console.log("addCompany", router)
                //     router.push({ path: '/directory/new-company/clients/5' })
                //   }
                // }
              }
            },
            affairsContainer:{
              ref: "affairsContainer",
              type: 'container',
              name: 'affairsContainer',
              cls:'',
              hidden: true,
              width:'100%',
              items:{
                affairsList: {
                  ref: "affairsList",
                  name: "AffairId",
                  flex:1,
                  label: "Affaire",
                  type: 'combo',
                  required: true,
                  items: store.getters.affairsList.map(el => ({
                    text: el.name,
                    value: el.id,
                    color: el.color,
                    data: el
                  })),
                  listItemTpl: item => xss `<div class="pin-color" style="background-color:${(item.color!==''?item.color:'#0c3571')} !important"></div><div>${item.text}</div>`,
                  hidden: true,
                  picker: {},
                  readOnly: false,
                  onChange({
                    source,
                    value
                  }) {
                    const dataAffair = store.getters.affairsList.find(el => el.id == value)
                    if (dataAffair) {
                      source.parent.parent.widgetMap.nameField.value = dataAffair.code ? dataAffair.code : dataAffair.name ? dataAffair.name : ""
                    } else {
                      source.parent.parent.widgetMap.nameField.value = ""
                    }
                  }
                }
                // addAffair:{
                //   type: 'button',
                //   cls:'btn b-transparent btn-outline-primary', 
                //   text: "",
                //   color   : 'outline-primary',
                //   icon    : 'b-fa-thin b-fa-plus',
                //   style   : 'max-height: 38px; margin-top: 19px;',
                //   onClick() {
                //     console.log("addAffair", router)
                //     router.push({ path: '/new-affair' })
                //   }
                // }
              }
            },
            // resourcesField : {
            //   label: "Ressources",
            //   type: 'combo',
            // },
            // Champs masqué qui porte la proriété style que nous générons via le type d'event ou l'entreprise
            styleField: {
              ref: "styleField",
              type: 'textfield',
              label: 'Style',
              hidden: true,
              name: 'style'
            },
            durationUnitField: {
              type: 'textfield',
              name: 'durationUnit',
              defaultValue: 'hour',
              value: 'hour',
              hidden: true
            },

            preambleField: {
              label: 'Travel to'
            },
            postambleField: {
              label: 'Travel from'
            },

            durationField:{
              flex :1,
              label: 'Durée totale (jours)'
            },
            startDateField: {
              // cls:"ml-1",
            },
            hoursDayField: {
              flex :1,
              ref: 'hoursListField',
              type: 'numberfield',
              name: 'HoursDay',
              cls:"ml-1",
              label: 'Durée hebdomadaire (heures)',
              weight:500
            }



        }

        },
        notesTab: {
          title: "Détails",
          tab: {
            icon: null,
            text: 'Détails'
          },
          activeIndex:3,
          icon:'',
          weight:1,
          items: {
            noteField:{
              ref: "CommentsField",
              name: "Comments",
              label: "Description",
              onChange(change) {
                change.source._parent._parent._widgetMap.smsField.value=change.value
              }
            }
          }
        },
        notificationTab: {
          activeIndex:1,
          title: "Notifications",
          weight:2,
          items: {
            alerteSmsField: {
              ref: "alerteSmsField",
              type: 'container',
              name: 'alerteSmsField',
              hidden:true,
              html:"<div class='alert-warning text-warning p-1 mb-1'>Pour programmer un SMS, votre événement doit être associé à au moins un collaborateur disposant d'un numéro de téléphone renseigné dans votre base de données.</div> "
            },

            smsForm: {
              ref: "smsForm",
              type: 'container',
              name: 'smsForm',
              cls:'',
              hidden:true,
              items:{
                dateSmsField: {
                  ref: "dateSmsField",
                  label: "Date d'envoi",
                  type: 'datetimefield',
                  required: false,
                  name: 'dateSmsField',
                  hidden: false,
                  weight:100,
                  value : new Date(),
                },
                phoneField: {
                  ref: "phoneField",
                  label: "Destinataire",
                  readOnly:true,
                  with:100,
                  // flex:1,
                  items: [],
                  itemTpl: item => `
                  <td> ${item.firstName} ${item.lastName}</td>
                  <td>${item.phoneNumber}</td>`,
                  type: 'container',
                  required: false,
                  name: 'phone',
                  hidden: false,
                  weight:200
                },
                smsField: {
                  ref: "smsField",
                  label: "Contenu du sms",
                  type: 'textareafield',
                  required: false,
                  name: 'sms',
                  hidden: false,
                  resize:true,
                  weight:300
                },
                sendSms: {
                  ref: "sendSmsButton",
                  text: "Programmer le SMS",
                  flex:1,
                  width:'100%',
                  type: 'button',
                  cls:'btn-outline-primary text-primary',
                  required: false,
                  name: 'sendSms',
                  hidden: false,
                  weight:400,
                  onClick: (click) => {
                    click.source._parent._parent._parent._widgetMap.smsForm.readOnly=true
                    click.source._parent._parent._parent._widgetMap.smsForm.disabled=true
                    click.source._parent._parent._parent._widgetMap.smsForm.cls='opacity-025'

                    //tableau des ids des collaborateurs des planningLineId de resourcesIdsOfEvent
                    let collaboratorIds = Scheduler.instance._project.resourceStore._data.filter(el => click.source._parent._parent._parent._widgetMap.resourcesField._lastValue.includes(el.Id)).map(el => el.CollaboratorId);
                    var tzoffset = ((click.source._parent._parent._parent._widgetMap.dateSmsField.value).getTimezoneOffset() * 60000)+7200000; //offset en milliseconds
                    var localISOTime = (new Date(click.source._parent._parent._parent._widgetMap.dateSmsField.value - tzoffset)).toISOString().slice(0, -1);

                    store.dispatch('sendSms', {
                      collaboratorIds: collaboratorIds,
                      text: click.source._parent._parent._widgetMap.smsField.value,
                      sendAt: localISOTime,
                      withResponse: false
                    }).then(res => {
                      click.source._parent._parent._parent._widgetMap.alerteSmsField.html="<div class='alert-success text-success p-1 mb-1'>Votre SMS a été correctement programmé !<br> Vous pouvez à tout moment effectuer un nouvel envoi et consulter l'historique de vos SMS depuis l'onglet 'Paramètres > Gestion des SMS'</div> "
                      click.source._parent._parent._parent._widgetMap.alerteSmsField.hidden=false
                      click.source._parent._parent._parent._widgetMap.smsForm.readOnly=false
                      click.source._parent._parent._parent._widgetMap.smsForm.disabled=false
                      click.source._parent._parent._parent._widgetMap.smsForm.cls=''
                    })
                  }
                }
              }
            }
          },
        },
        advancedTab: {
          activeIndex:2,
          title: "Paramètres",
          weight: 3,
          hidden:true
        },
        predecessorsTab: false,
        successorsTab: false
      },
      editorConfig: {
        autoClose : false,
        title: 'Edition d\'un évènement',
        activeTab:0,
        bbar: {

          layout: {
            justify: 'space-between',
          },
          items: {
            saveButton: {
              type: 'button',
              text: 'Enregistrer',
              icon: 'b-fa b-fa-plus',
              cls: 'btn float-left btn-primary text-white white',
              weight: 0,
            },
            cancelButton: null,
            deleteButton: {
              type: 'button',
              text: '',
              icon: 'b-fa b-fa-trash',
              cls: 'btn float-right btn-outline-danger danger',
              alignSelf: 'strech',
              weight: 2,
            }
          }
        }
      }
    },
    stripe: true,
    // timeRanges: true,
    timeRanges : {
      showCurrentTimeLine : true,
      showHeaderElements  : false
    }
};

// multiSelect : true,
// dragConfig  : { enableCopy : true },
// timeRanges : {
//     enableResizing      : true,
//     showCurrentTimeLine : true,
//     showHeaderElements  : true,
//     tooltipTemplate     : ({ timeRange }) => timeRange.name
// },
// resourceTimeRangesFeature: true,
// resourceTimeRanges: [
//   {
//     id: 7,
//     resourceId: 21,
//     startDate: "2022-04-26",
//     endDate: "2022-04-27",
//     name: "AFK (uses timeRangeColor)",
//     cls: "red"
//   }
// ]
// let sendSmsButton = new Button({
//   appendTo : 'first',
//   badge    : 3,
//   text     : 'Showing badge',
//   color    : 'b-blue',
//   onClick
// });
