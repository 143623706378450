import {
  Scheduler
} from "@/views/Bryntum.vue"
import {
  DomClassList, PresetStore, PresetManager, DateHelper, scheduler
} from "@bryntum/schedulerpro"
import router from "@/router"
import RouterTab from 'vue-router-tab'

PresetManager.registerPreset('dayAndWeek', {
  // name              : 'Vue Semaines',
  // tickWidth         : 35,
  // rowHeight         : 32,
  // displayDateFormat : '{w.}W YYYY',
  // shiftIncrement    : 1,
  // shiftUnit         : 'year',
  // timeResolution    : {
  //     unit      : 'd',
  //     increment : 1
  // },
  // defaultSpan     : 24,
  // mainHeaderLevel : 1,
  base          : "dayAndWeek",
  name              : 'Vue standard',
  tickWidth     : 40,
  timeResolution: {
    unit     : "day",
    increment: 1,
  },
  headers         : [
    {
      unit      : "month",
      dateFormat: "MMMM YYYY",
      align     : "start",
    },
    {
      unit    : "week",
      renderer: function (start) {
        return (
          "Semaine  <strong>" + DateHelper.format(start, "WW") + "</strong>"
        );
      },
    },
    {
      unit    : "day",
      renderer: (start, end, headerConfig, index) => {
        headerConfig.headerCellCls = "text-center";
        if (
          DateHelper.format(start, "YYYY-MM-DD") ==
          DateHelper.format(new Date(), "YYYY-MM-DD")
        ) {
          headerConfig.headerCellCls = "text-center bg-primary text-white";
          return (
            DateHelper.format(start, "dd") +
            "<br><strong>" +
            DateHelper.format(start, "DD") +
            "</strong>"
          );
        }
        return (
          DateHelper.format(start, "dd") +
          "<br><strong>" +
          DateHelper.format(start, "DD") +
          "</strong>"
        );
      },
    }
  ]
});
PresetManager.registerPreset('weekAndDay', {
  base          : "weekAndDay",
  name              : 'Vue détaillée semaine',
});
PresetManager.registerPreset('hourAndDay', {
  base          : "hourAndDay",
  name              : 'Vue détaillée heure',
});
PresetManager.registerPreset('minuteAndHour', {
  base          : "minuteAndHour",
  name              : 'Vue détaillée minutes',
});
const
    requiredPresetIds = {
        dayAndWeek   : 1,
        weekAndDay        : 1,
        hourAndDay        : 1,
        minuteAndHour     : 1,
        dayNightShift     : 1,
        // weekAndMonth      : 1,
        // weekAndDayLetter  : 1,
        // weekDateAndMonth  : 1,
        // weekNumberAndYear : 1,
        // monthAndYear      : 1,
        // year              : 1,
        // manyYears         : 1
    },
    // Create a presets store with just the ones we want.
    // The set of available Presets is what provides the zoom levels.
    presets     = PresetManager.records.filter(p => requiredPresetIds[p.id]),
    presetStore = new PresetStore({
        data : presets,

        // We'd like the order to go from seconds to years, rather than the default order
        zoomOrder : -1
    });

export let toolBar = {
  title: '',
  items: [
    {
      type: 'displayField',
      label: "Planning",
    },

    {
      type: 'displayField',
      label: "",
    },
    // {
    //   type: 'button',
    //   text: "Paramètre de la vue",
    //   onClick() {
    //     router.push("/planning/settings/edit-planning-view/"+router.history.current.params.id)
    //   }
    // },

    '->',
    // {
    //     type  : 'buttongroup',
    //     items : [
    //         {
    //             type    : 'button',
    //             icon    : 'b-fa-angle-left',
    //             tooltip : 'View previous day',
    //             onAction() {
    //                 scheduler.shiftPrevious();
    //             }
    //         },
    //         {
    //             type    : 'button',
    //             ref     : 'todayButton',
    //             text    : 'Today',
    //             tooltip : 'View today, to see the current time line',
    //             onAction() {
    //                 const today = DateHelper.clearTime(new Date());
    //                 today.setHours(5);
    //                 scheduler.setTimeSpan(today, DateHelper.add(today, 18, 'hour'));
    //             }
    //         },
    //         {
    //             type    : 'button',
    //             icon    : 'b-fa-angle-right',
    //             tooltip : 'View next day',
    //             onAction() {
    //                 scheduler.shiftNext();
    //             }
    //         }
    //     ]
    // },
    // {
    //   type : 'date',
    //   text : 'Date Picker',
    //   ref  : 'myDatePicker'
    // },
    {
      type: 'textfield',
      ref: 'filterByName',
      flex:'1',
      icon: 'b-fa b-fa-filter',
      placeholder: 'Filtrer par nom',
      clearable: true,
      keyStrokeChangeDelay: 100,
      triggers: {
        filter: {
          align: 'start',
          cls: 'b-fa b-fa-filter'
        }
      },
      onChange({
        value
      }) {
        value = value.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
        // Replace all previous filters and set a new filter
        Scheduler.instance.eventStore.filter({
          filters: event => event.name.match(new RegExp(value, 'i')),
          replace: true
        });

      }
    },
    {
        type         : 'combo',
        width        : 200,
        ref          : 'presetCombo',
        cls          : 'ml-1',
        placeholder  : 'Preset',
        editable     : false,
        store        : presetStore,
        valueField   : 'id',
        displayField : 'name',
        value        : 'dayAndWeek',
        picker       : {
            maxHeight : 500
        },
        onChange({ source : combo }) {
            Scheduler.instance.zoomToLevel(combo.selected);
        }
    },
    {
      type  : 'buttongroup',
      items : [
          {
              icon    : 'b-fa-location-crosshairs',
              cls :'btn px-1 ml-1 btn-outline-primary', 
              color   : 'outline-primary',
              style:'border-top-right-radius:0px !important; border-bottom-right-radius:0px !important;',
              onClick() {
                Scheduler.instance.scrollToDate(new Date())
              }
          },
          {
              icon    : 'b-fa-rotate',
              cls:'btn px-1 btn-outline-primary',
              style:'border-top-left-radius:0px !important; border-bottom-left-radius:0px !important; border-left:1px solid #0c3571 !important',
              color   : 'outline-primary',
              onClick() {
                router.go()
              }
          }
      ]
    },
    {
      type: 'button',
      cls:'btn px-1 ml-1 b-transparent btn-outline-primary', 
      text: "",
      color   : 'outline-primary',
      icon    : 'b-fa-thin b-fa-gear',
      onClick() {
        router.push("/planning/settings/edit-planning-view/"+router.history.current.params.id)
      }
    },
    // {
    //   type: 'buttongroup',
    //   cls         : 'b-raised',
    //   toggleGroup : true,
    //   items:[
    //     {
    //       type: 'button',
    //       cls:'btn px-1 ml-1 b-transparent btn-outline-primary', 
    //       text: "",
    //       color   : 'outline-primary',
    //       icon    : 'b-fa-thin b-fa-gear',
    //       onClick() {
    //         router.push("/planning/settings/edit-planning-view/"+router.history.current.params.id)
    //         //router.reload()
    //       }
    //     },
    //     {
    //       type: 'button',
    //       cls:'btn px-1 ml-1 b-transparent btn-outline-primary', 
    //       text: "",
    //       color   : 'outline-primary',
    //       icon    : 'b-fa-rotate-right',
    //       onClick() {
    //         router.go()
    //       }
    //     }
    //   ]
    // },
    // {
    //   type: 'textfield',
    //   ref: 'highlight',
    //   placeholder: 'Mettre en évidance',
    //   clearable: true,
    //   keyStrokeChangeDelay: 100,
    //   triggers: {
    //     filter: {
    //       align: 'start',
    //       cls: 'b-fa b-fa-search'
    //     }
    //   },
    //   onChange({
    //     value
    //   }) {
    //     Scheduler.instance.eventStore.forEach(task => {
    //       const taskClassList = new DomClassList(task.cls);

    //       if (value !== '' && task.name.toLowerCase().includes(value.toLowerCase())) {
    //         taskClassList.add('b-match');
    //       } else if (value !== '' && !task.name.toLowerCase().includes(value.toLowerCase())) {
    //         taskClassList.add('no-b-match');

    //       } else {
    //         taskClassList.remove('b-match');
    //         taskClassList.remove('no-b-match');
    //       }

    //       task.cls = taskClassList.value;
    //     });

    //     Scheduler.instance.element.classList[value.length > 0 ? 'add' : 'remove']('b-highlighting');
    //   }
    // }
  ]
}
