<template>
  <!-- <div class="page-container"> -->

  <div>
    <b-row class="m-0">
      <b-col
        cols="12"
        style="margin-bottom: 0px !important; padding-bottom: 0px !important;"
      >
        <div class="page-container">
          <bryntum-scheduler-pro
            ref="scheduler"
            :key="planningView.id"
            v-bind="schedulerConfig"
            v-if="loaded"
          />
        </div>  
      </b-col> 
    </b-row>  
    <!-- <AppCustomizer v-if="scheduler" :schedulerpro="scheduler" /> -->
  </div>
</template> 

<script>
import { StringHelper } from "@bryntum/schedulerpro";
const { xss } = StringHelper

import { LocaleManager } from '@bryntum/schedulerpro'
import localeFr from '@/locales.Fr'
LocaleManager.registerLocale('Fr', {
  desc: 'French',
  locale: localeFr
})
LocaleManager.applyLocale('Fr')
import { mapGetters, mapActions } from "vuex";
import { BryntumSchedulerPro } from '@bryntum/schedulerpro-vue'
import { init } from '@/schedulerConfig/AppConfig.js'

let Scheduler={}
let Bryntum = {
  name: 'app',
  components: { 
    BryntumSchedulerPro
  },
  props: {
    id: {
      default: 0,
    },
  },
  data () {
    return {
      planningView:{},
      schedulerConfig: null,
      showEditor: false,
      eventRecord: null,
      eventStore: null,
      resourceId: null,
      assignmentStore: null,
      scheduler: undefined,
      loaded: false
    }
  },
  computed: {
    ...mapGetters([
      "planningViewsList",
      "planningLinesFree",
      "planningLinesCollaborator",
      "planningLinesAffair",
      "eventTypesList",
      "companiesList",
      "affairsList",
      "publicHolidayList",
      "collaboratorsList"
    ])
  },
  // async beforeCreate(){
  //   await this.$store.dispatch('fetchEventTypesList');
  //   await this.$store.dispatch('fetchCompaniesList');
  //   await this.$store.dispatch('fetchAffairsList');
  // },
  async mounted () {
    this.setPlanningParams();
  },
  methods: {
    ...mapActions([
      "fetchEventTypesList",
	    "fetchCompaniesList",
	    "fetchAffairsList",
      "fetchPlanningView",
      "fetchPublicHolidayList"
    ]),
    setPlanningParams(){
      let _this = this
      this.fetchPlanningView(this.id).then(res => { 
        this.planningView=res
        /*
        isCurrentDay: (…)
        */
        this.$store.dispatch('fetchEventTypesList').then(async () => {
          await this.$store.dispatch('fetchCompaniesList').then(async () => {
            await this.$store.dispatch('fetchAffairsList').then(() => {
              init()
                .then(config => {
                  this.schedulerConfig = config
                  this.schedulerConfig.tbar.items[1].label=this.planningView.name
                  if(this.planningView.isShowColGroup){
                    this.schedulerConfig.columns[this.schedulerConfig.columns.findIndex(elem=>elem.field=='group')].hidden=false
                  }else{
                    this.schedulerConfig.columns[this.schedulerConfig.columns.findIndex(elem=>elem.field=='group')].hidden=true
                  }
                  
                  
                  // Affichage par nom
                  if(this.planningView.isAutoOrderedByName){
                    this.schedulerConfig.features.sort= {
                      field: 'name',
                      ascending: true
                    }
                  }else{
                    this.schedulerConfig.features.sort= {
                      field: 'position',
                      ascending: true
                    }
                  }

                  // Affichage par groupe
                  if(this.planningView.isAutoOrderedByGroup){
                    this.schedulerConfig.features.group= {
                      field: 'group',
                      ascending: true
                    }
                  }else{
                    this.schedulerConfig.features.group=false
                  }

                  // Affichage par position
                  if(!this.planningView.isAutoOrderedByName && !this.planningView.isAutoOrderedByGroup){
                    this.schedulerConfig.features.sort= {
                      field: 'position',
                      ascending: true
                    }
                  }

                  // Affichage des jours fériés
                  /*
                  if(this.planningView.isPublicHoliday){
                                    this.schedulerConfig.project.timeRangeStore={
                                      data : this.publicHolidayList
            //                           [{
            //     "id"             : 1,
            //     "name"           : "Morning coffee",
            //     "recurrenceRule" : "FREQ=WEEKLY;BYDAY=MO;",
            //     "startDate"      : "2019-02-07 08:00"
            // },
            // {
            //     "id"             : 2,
            //     "name"           : "Friday, yay",
            //     "recurrenceRule" : "FREQ=WEEKLY;BYDAY=FR;",
            //     "startDate"      : "2019-02-07 00:00",
            //     "endDate"        : "2019-02-08 00:00"
            // }]
                                    }
                  }*/

                  // Activation de la barre de recherche
                  if(!this.planningView.isActiveSearch){
                    this.schedulerConfig.tbar.items[3]=""
                  }

                  this.$nextTick(() => {
                    this.scheduler = this.$refs['scheduler']
                    this.schedulerConfig.eventRenderer=this.eventRenderer
                    this.schedulerConfig.project.transport.load.params.planningViewId = this.$route.params.id
                
                    this.loaded = true
                    this.$nextTick(() => {
                      Scheduler = this.$refs['scheduler']
                      Scheduler.instance.resourceStore.filterBy(function(resource) {
                        if(!resource.PlanningLineId || resource.typeLine==3 || resource.typeLine==4 || resource.IsLocked){
                          // resource.calendar = "noworking"
                          resource.readOnly = true
                        }
                        return resource
                      });

                    })
                  })
                })
                .catch(err => {})
            })
          })
        })
      })
    },
    // setEventStyle (style) {
    //   const eventStore = this.scheduler.instance.eventStore

    //   eventStore.forEach(eventRecord => {
    //     if (!this.storedOriginalStyles) {
    //       eventRecord.originalStyle = eventRecord.eventStyle
    //     }

    //     if (style === 'mixed') {
    //       eventRecord.eventStyle = eventRecord.originalStyle
    //     } else {
    //       eventRecord.eventStyle = style
    //     }
    //   })
    //   this.storedOriginalStyles = true
    // },
    eventRenderer({ eventRecord, resourceRecord, renderData }) {
      let title = "";
      let subTitle = "";
      let uniteHebdo = "jour";
      if(eventRecord.EventTypeId && eventRecord.EventTypeId>0 && this.eventTypesList.find(elem=>elem.id==eventRecord.EventTypeId)){
          if(eventRecord.name!==this.eventTypesList.find(elem=>elem.id==eventRecord.EventTypeId).label){
            subTitle=this.eventTypesList.find(elem=>elem.id==eventRecord.EventTypeId).label
          }
          if(this.eventTypesList.find(elem=>elem.id==eventRecord.EventTypeId).label.includes("nuit")){
            uniteHebdo="nuit"
          }
      }
      if(eventRecord.CompanyId && eventRecord.CompanyId>0 && this.companiesList.find(elem=>elem.id==eventRecord.CompanyId)){
        title+=' / '+this.companiesList.find(elem=>elem.id==eventRecord.CompanyId).name
      }
      // if(eventRecord.AffairId && eventRecord.AffairId>0){
      //   subTitle+=' / '+this.affairsList.find(elem=>elem.id==eventRecord.AffairId).name
      // }
      if(eventRecord.HoursDay && eventRecord.HoursDay>0){
        if(subTitle==""){
          subTitle=eventRecord.HoursDay+'h/'+uniteHebdo
        }else{
          subTitle+=' / '+eventRecord.HoursDay+'h/'+uniteHebdo
        }
      }

      return StringHelper.xss`<div class="bryntumEvent"><div class="bryntumEventTitle">${eventRecord.name}${title}</div><div class="bryntumEventSubTitle">${subTitle}</div>`;
    }
  }
}
export { Scheduler }
export default Bryntum
</script>

<style lang="scss" scoped>
@import '../App.scss';
</style>

<style>
.b-match {
  opacity: 1 !important;
}
.no-b-match {
  opacity: 0.2 !important;
}
.page-container {
  height: calc(100vh - 155px) !important;
  overflow: hidden;
  width: 100%;
}
.b-textareafield input{
  min-height: 300px
}
</style>
